import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";

// ルーティングの設定
const routes = [
  {
    // どのPathにもマッチングしない場合は404ページに移動
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "Home" },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { title: "About" },
  },
  {
    path: "/post",
    name: "post",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PostView.vue"),
    meta: { title: "Post" },
  },
  {
    path: "/post/:id",
    name: "PostShow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PostShow.vue"),
    props: true,
    meta: { title: "PostShow" },
  },
];

// router生成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// beforeEachはページ移動事に実行される
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
